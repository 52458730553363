import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow, TableContainer } from "@mui/material";
import {AnalyticsReports} from 'components/analytics/AnalyticsReports';
import { useAuth } from 'contexts/AuthContext';
import { req } from "utils/api";

export function CustomerHome() {
  const { user } = useAuth();
  const [account, setaccount] = useState(null);



  useEffect(() => {
    if (user) {
      fetchaccount();
    }
  }, [user]);

  
  const fetchaccount = async () => {
    try {
      const response = await req("get", "customer/get_account");
      setaccount(response.data);
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  if (!user || !account) {
    return <div>Loading...</div>;
  }
  
  console.log("account", account);
  console.log("user", user);

  return (
    <div id="home" className="flex flex-auto flex-col relative">
      <div className="flex w-full min-w-0 flex-col sm:absolute sm:inset-0 sm:overflow-hidden">
        <div className="flex-auto relative sm:overflow-hidden sm:h-full">
          <div className="relative h-full overflow-auto">
            <div className="absolute bg-blue-gradient w-full -z-1 h-60"></div>
            <div className="flex flex-auto w-full justify-center sm:p-6 md:p-8">
              <div className="flex w-full flex-auto flex-col max-w-6xl">
                <Header account={account} />
                <MainContent account={account} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Header({ account }) {
  return (
    <div className="text-white sm:py-6 md:py-8">
      <h1 className="text-5xl font-extrabold leading-none">
        Welcome {account.login_name || ""}
      </h1>
      <p className="mt-2">
        <strong>User profile number</strong>{" "}
        {account.user_id || "123456789"}
      </p>
    </div>
  );
}

function MainContent({ account }) {
  const { secondary_logins } = account; // Destructure secondary_logins from account object

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-12">
      <div className="md:col-span-8">

        <div className="flex w-full flex-auto flex-col bg-white sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
      <div className="flex flex-col p-0">
      <AnalyticsReports role="customer" />
      </div>
    </div>
      </div>
      <div className="md:col-span-4">
        <ContentCard>
          <div className="text-2xl font-extrabold leading-none">Team Members</div>
          <div className="flex flex-col mt-4">
          <TableContainer>
              <Table>
                <TableBody>
                  {secondary_logins?.length > 0 ? (
                    secondary_logins.map((login, index) => (
                      <TableRow key={index}>
                        <TableCell className="pl-0">{login.login_name}</TableCell>
                        <TableCell className="pr-0 capitalize" align="right">{login.login_type}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No team members available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="flex flex-col mt-4">
            <Link to="/customer/settings?n=team" className="text-md">View more Team Members ...</Link>
          </div>
        </ContentCard>
      </div>
    </div>
  );
}

function ContentCard({ children }) {
  return (
    <div className="flex w-full flex-auto flex-col bg-white sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
      <div className="flex flex-col p-4">
        {children}
      </div>
    </div>
  );
}

export default CustomerHome;
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { addDays, format } from "date-fns";
import { ContainersTable } from "./ContainersTable";
import { DepositDailyBarChart } from "./DepositDailyBarChart";
import { MaterialPieChart } from "./MaterialPieChart";
import { EnvironmentalStats } from "./EnvironmentalStats";
import { req } from "../../utils/api";

export function AnalyticsReports({ role }) {
  const baseUrl = role === "admin" ? "admin" : "customer";
  const [totalUnits, setTotalUnits] = useState(0);
  const [tabValue, setTabValue] = useState(1);
  const [materialStats, setMaterialStats] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const fetchData = async () => {
    let formattedStart = encodeURIComponent(
      startDate.toISOString().split("T")[0]
    );
    let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);
    const stats = await req(
      "GET",
      `${baseUrl}/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`
    );
    const units = calculateTotalUnits(stats);
    setTotalUnits(units);
    setMaterialStats(stats);
  };
  useEffect(() => {
    fetchData();
    //set page title
    document.title = "Analytics Admin Reports";
  }, []);
  useEffect(() => {
    fetchData();
    //refresh dataTable
  }, [startDate, endDate]);
  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));

    // Only close the Popover when both dates are selected
    if (startDate && endDate && startDate !== endDate) {
      setAnchorEl(null); // Close the popover after selecting the date range
    }
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  return (
    <Box className="w-full relative" sx={{ p: 0 }}>
      <Box
        className="flex flex-auto flex-col w-full relative"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 2,
          p: 0,
          boxShadow: 1,
        }}
      >
        <Button variant="text" fullWidth onClick={handleOpenPopover}>
          {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false} // Ensure the popover stays open until both dates are selected
          />
        </Popover>

        <TabContext value={tabValue}>
          <Box sx={{ width: "100%" }}>
            <TabList
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="Stats tabs"
              variant="fullWidth"
              centered
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label="Deposits details" value={0} />
              <Tab label="Environmental stats" value={1} />
              <Tab label="Product details" value={2} />
              <Tab label="Daily stats" value={3} />
            </TabList>

            <TabPanel value={0} index={0}>
              <ContainersTable role={role} startDate={startDate} endDate={endDate} />
            </TabPanel>
            <TabPanel value={1} index={1}>
              <EnvironmentalStats totalUnits={totalUnits} />
            </TabPanel>
            <TabPanel value={2} index={2}>
              <MaterialPieChart materialStats={materialStats} />
            </TabPanel>
            <TabPanel value={3} index={3}>
              <DepositDailyBarChart materialStats={materialStats} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
}

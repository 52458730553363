import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// 3. Component Imports:
import DataTable from "../../components/DataTable";
import { CreateEditLocation } from "../../components/locations/CreateEditLocation";
import { SnackbarNotification } from "../../components/SnackbarNotification";

// 4. API Utility Imports:
import { req } from "utils/api";

export function AdminLocations() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/

  const [refreshCounter, setRefreshCounter] = useState(0); // State to trigger refresh
  const [dataDrawerOpen, setDataDrawerOpen] = useState(false);
  const [location, setLocation] = useState({}); // State to hold the location being edited

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const linkAccountsFormatter = (value, row) => {
    const linkedAccounts = {};

    row.accounts_with_access.forEach((item) => {
      if (!linkedAccounts[item.location_relationship]) {
        linkedAccounts[item.location_relationship] = 1;
      } else {
        linkedAccounts[item.location_relationship]++;
      }
    });

    return (
      <Stack direction="row" spacing={1}>
        {Object.entries(linkedAccounts).map(([type, count]) => (
          <Chip
            key={type}
            label={`${type}: ${count}`}
            size="small"
            color="primary"
            variant="outlined"
          />
        ))}
      </Stack>
    );
  };

  const columns = [
    { field: "location_g_id", headerName: "ID" },
    { field: "location_name", headerName: "Name" },
    { field: "address", headerName: "Address" },
    {
      field: "accounts_with_access",
      headerName: "Linked Accounts",
      formatter: linkAccountsFormatter,
    },
  ];

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const closeDataDrawer = () => {
    setDataDrawerOpen(false);
  };

  const reloadLocations = () => {
    setRefreshCounter((prev) => prev + 1); // Increment the counter to trigger a refresh
  };

  const reloadTable = () => {
    // Trigger a refresh by incrementing the counter
    setRefreshCounter((prev) => prev + 1);
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleLocationCreated = (location) => {
    reloadLocations();
    closeDataDrawer();
    //   editLocation(location); // TODO: Uncomment this line if you want to edit the location after creation
  };

  const handleLocationUpdated = () => {
    reloadLocations();
    closeDataDrawer();
  };

  const editLocation = (location) => {
    setLocation(location); // Set the location to be edited
    setDataDrawerOpen(true); // Open the drawer
  };

  const createLocation = () => {
    setLocation({}); // Clear the location to indicate a new entry
    setDataDrawerOpen(true); // Open the drawer
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "Manage Locations";
  }, []);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const deleteLocation = async (location_id) => {
    try {
      await req("post", "admin/delete_location", { id: location_id });
      setSnackbarMessage("Location deleted successfully.");
      setSnackbarSeverity("success");

      reloadLocations(); // Reload locations after deletion
      closeDataDrawer(); // Close the drawer after deletion
    } catch (error) {
      console.error("Failed to delete location:", error);
      setSnackbarMessage(`Failed to delete account: ${error}`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteLinkedAccount = async (data) => {
    try {
      await req("post", "admin/remove_account_from_location", data);
      setSnackbarMessage("Linked Account removed from location successfully.");
      setSnackbarSeverity("success");
      reloadLocations(); // Reload locations after deletion
      reloadTable(); // Reload the table to update the linked accounts
    } catch (error) {
      console.error("Failed to remove the linked account from the location:", error);
      setSnackbarMessage(`Failed to remove the linked account from the location: ${error}`);
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={dataDrawerOpen}
        onClose={closeDataDrawer}
        PaperProps={{
          sx: {
            width: "50%", // Set the width of the drawer to 50% of the screen
          },
        }}
      >
        <CreateEditLocation
          closeDataDrawer={closeDataDrawer}
          reloadTable={reloadTable}
          location={location} // Pass the location to the component
          handleLocationCreated={handleLocationCreated}
          handleLocationUpdated={handleLocationUpdated}
          deleteLocation={deleteLocation}
          deleteLinkedAccount={deleteLinkedAccount}
        />
      </Drawer>

      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, boxShadow: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Locations
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Review & Manage Locations
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="text-right">
              <Button variant="contained" onClick={createLocation}>
                Create Location
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <DataTable
              url="admin/get_locations"
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              refresh={refreshCounter}
              rowClickAction={editLocation}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}

import { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";

import { LinkedAccountsTable } from "./LinkedAccountsTable";
import { req } from "utils/api";

export function CreateEditLocation({
  location = {},
  reloadTable,
  closeDataDrawer,
  handleLocationCreated,
  handleLocationUpdated,
  deleteLocation,
  deleteLinkedAccount,
}) {
  /*--------------------------------------
    State Variables
  -------------------------------------*/
  const [locationName, setLocationName] = useState(null);
  const [locationGid, setLocationGid] = useState(null);
  const [locationAddress, setLocationAddress] = useState(null);
  const [locationAccounts, setLocationAccounts] = useState(
    location?.accounts_with_access || []
  );
  const [accounts, setAccounts] = useState([]);
  const [searchAccounts, setSearchAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openRelationshipDialog, setOpenRelationshipDialog] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [formProcessing, setFormProcessing] = useState(false);

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/

  const validateForm = () => {
    let formErrors = {};

    if (!locationName) {
      formErrors.name = "Location name is required.";
    }
    if (!locationGid) {
      formErrors.locationGid = "Location GID is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const data = {
      location_name: locationName,
      location_g_id: locationGid,
      address: locationAddress,
    };

    try {
      if (location.location_id) {
        // Update existing location
        let post = {
          id: location.location_id,
          data,
        };
        setFormProcessing(true);
        await req("post", `admin/update_location`, post);
        handleLocationUpdated();
        setFormProcessing(false);
      } else {
        // Create new location
        const res = await req("post", "admin/create_location", data);
        console.log("Location created:", res);
        handleLocationCreated(location);
      }
    } catch (error) {
      console.error("Failed to submit form:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowLinkAccount = () => {
    document.getElementById("linkAccount").classList.remove("hidden");
  };

  const handleHideLinkAccount = () => {
    document.getElementById("linkAccount").classList.add("hidden");
  };

  const handleAccountSearch = async (searchTerm) => {
    if (searchTerm && accounts.length > 0) {
      //console.log("Searching for:", searchTerm, allLocations);
      setSearchAccounts(
        accounts.filter((item) =>
          item.account_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setSearchAccounts([]);
    }
  };

  const handleAccountSelect = (event, newValue) => {
    if (newValue) {
      setSelectedAccount(newValue);
      setOpenRelationshipDialog(true);
    }
  };

  const handleRelationshipSelect = async () => {
    if (selectedAccount && selectedRelationship) {
      try {
        let response = await req("post", "admin/add_account_to_location", {
          account_id: selectedAccount.account_id,
          location_id: location.location_id,
          relationship: selectedRelationship,
        });

        console.log("Response from add_account_to_location:", response);
        //if good response then remove it from the customer.account_locations
        handleHideLinkAccount();

        setOpenRelationshipDialog(false);
        setSelectedAccount(null);
        setSelectedRelationship("");
        reloadTable();
        // setLocationAccounts(location?.accounts_with_access);
      } catch (error) {
        console.error("Error associating location:", error);
        alert("Failed to associate location.");
      } finally {
        setRefreshCounter((prev) => prev + 1);
      }
    }
  };

  const handleDeleteLinkedAccount = async (accountToDelete) => {
    
    const data = {
      account_id: accountToDelete.account_id,
      location_id: location.location_id,
    }
    deleteLinkedAccount(data);
    setRefreshCounter((prev) => prev + 1); // Force the drawer to refresh
  };

  const handleClickDeleteLocation = () => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      const locationId = location.location_id;
      deleteLocation(locationId);
    }
  };

  useEffect(() => {
    if (location) {
      setLocationName(location.location_name || "");
      setLocationGid(location.location_g_id || "");
      setLocationAddress(location.address || "");
      //   setLocationAccounts(location.accounts_with_access || []);
    }
    getAccounts();
  }, [location, refreshCounter]);

  async function getAccounts() {
    try {
      let result = await req("get", "admin/get_accounts?type=customer");
      setAccounts(result.data.rows);
      //   console.log("Accounts:", result.data); // Debugging
    } catch (error) {
      console.error("Failed to read account locations:", error);
    }
  }

  // console.log("Location:", location); // Debugging

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">
              {location.location_id ? "Edit Location" : "Add New Location"}
            </div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 pt-0 sm:p-12 sm:pt-0">
        <div className="w-full max-w-3xl border-t">
          {loading ? (
            <div className="flex justify-center items-center py-16">
              <CircularProgress />
            </div>
          ) : (
            <form noValidate>
              <div className="mt-8">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_name">Location Name</label>
                  <TextField
                    id="location_name"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_email">Location GID</label>
                  <TextField
                    id="location_gid"
                    value={locationGid}
                    onChange={(e) => setLocationGid(e.target.value)}
                    error={!!errors.locationGid}
                    helperText={errors.locationGid}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Grid4x4Icon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="location_address">Location Address</label>
                  <TextField
                    id="location_address"
                    value={locationAddress}
                    onChange={(e) => setLocationAddress(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              {location.location_id && (
                <>
                  <Divider className="mt-8" />
                  <div className="mx-auto flex w-full max-w-3xl items-center justify-between pt-6">
                    <div>
                      <div className="text-2xl font-bold">Linked Accounts</div>
                    </div>
                    <div className="flex items-center ml-auto">
                      <Button
                        onClick={handleClickShowLinkAccount}
                        startIcon={<AddLinkIcon fontSize="small" />}
                      >
                        Link Account
                      </Button>
                    </div>
                  </div>
                  <div
                    id="linkAccount"
                    className="mx-auto flex w-full max-w-3xl items-center justify-between mt-4 pb-6 hidden"
                  >
                    <div className="w-full">
                      <label htmlFor="new_linked_account">Account</label>
                      <Autocomplete
                        options={searchAccounts}
                        getOptionLabel={(option) => option.account_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Start typing a name..."
                            variant="outlined"
                          />
                        )}
                        onInputChange={(event, newInputValue) =>
                          handleAccountSearch(newInputValue)
                        }
                        onChange={handleAccountSelect}
                        fullWidth
                      />
                    </div>
                  </div>
                </>
              )}
              {locationAccounts.length > 0 &&
              locationAccounts[0].account_id !== null ? (
                <div className="mt-4">
                  <div className="flex flex-auto flex-col">
                    <LinkedAccountsTable
                      data={locationAccounts}
                      handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                    />
                  </div>
                </div>
              ) : null}
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                <Button
                  onClick={handleClickDeleteLocation}
                  disabled={formProcessing}
                >
                  Delete
                </Button>
                <Button
                  onClick={closeDataDrawer}
                  variant="outlined"
                  className="ml-auto"
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-4"
                  variant="contained"
                  onClick={handleSubmit}
                  disableElevation
                  disabled={formProcessing}
                >
                  {formProcessing
                    ? "Creating..."
                    : location.location_id
                      ? "Update Location"
                      : "Create Location"}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Dialog
        open={openRelationshipDialog}
        onClose={() => setOpenRelationshipDialog(false)}
      >
        <DialogTitle>Select Relationship Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedRelationship}
            onChange={(e) => setSelectedRelationship(e.target.value)}
          >
            {[
              "Site Contact",
              "Property Manager",
              "LGA Contact",
              "Redemption Partner",
            ].map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRelationshipDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleRelationshipSelect} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuIcon from "@mui/icons-material/Menu";

export function CustomerTeam({
  data,
  handleDrawerToggle,
  handleChangeUserRole,
  handleCreateTeamMember,
  handleDeleteTeamMember,
}) {
  const [teamMembers, setTeamMembers] = useState(data?.secondary_logins || []);
  const loginNameRef = useRef(null);
  const emailRef = useRef(null);
  const [userRoles, setUserRoles] = useState(null);
  const [loading, setLoading] = useState(true);

  // Handler to change the role of a specific team member
  const handleRoleChange = (email, newRole) => {
    if (teamMembers.length === 1) {
      alert("There must be 1 primary account holder."); // TODO: Replace with a better alert
      return;
    }
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [email]: newRole,
    }));
    handleChangeUserRole(email, newRole); // Call the parent handler if needed
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const loginName = loginNameRef.current.value;
    const email = emailRef.current.value;
    handleCreateTeamMember({ login_name: loginName, email: email });
    // Reset the form fields if needed
    loginNameRef.current.value = "";
    emailRef.current.value = "";
  };

  useEffect(() => {
    setUserRoles(
      teamMembers.reduce(
        (acc, teamMember) => ({
          ...acc,
          [teamMember.email]: teamMember.login_type || "team member",
        }),
        {}
      )
    );
    setLoading(false);
  }, [teamMembers]);
  

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex-auto px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12">
          <div className="flex items-center">
            <IconButton
              className="-ml-2 lg:hidden mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
              onClick={handleDrawerToggle}
              aria-label="Toggle drawer"
            >
              <MenuIcon />
            </IconButton>
            <div className="ml-2 text-3xl font-bold leading-none tracking-tight lg:ml-0">
              Team Members
            </div>
          </div>
          <div className="mt-8">
            <div id="settings-team">
              <div className="w-full max-w-3xl ">
                <form onSubmit={handleSubmit}>
                  <div className="w-full">
                    <div className="text-xl">Add a new Team Member</div>
                  </div>
                  <div className="mt-4 flex flex-row divide-y">
                    <TextField
                      label="Name"
                      inputRef={loginNameRef}
                      required
                      fullWidth
                      size="small"
                    />
                    <TextField
                      label="Email"
                      type="email"
                      inputRef={emailRef}
                      required
                      fullWidth
                      size="small"
                      className="ml-4"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="ml-4"
                      disableElevation
                    >
                      Add
                    </Button>
                  </div>
                </form>
                <div className="mt-8 flex flex-col divide-y border-b border-t">
                  {teamMembers.map((teamMember) => (
                    <div
                      className="flex flex-col py-6 sm:flex-row sm:items-center"
                      key={teamMember.email}
                    >
                      <div className="flex items-center">
                        <div>
                          <div className="font-medium">
                            {teamMember.login_name}
                          </div>
                          <div className="text-secondary">
                            {teamMember.email}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex items-center sm:ml-auto sm:mt-0">
                        <div className="order-2 ml-4 sm:order-1 sm:ml-0">
                          <FormControl fullWidth>
                            <Select
                              id={`userRoleSelect-${teamMember.email}`}
                              value={
                                userRoles[teamMember.email] || "team member"
                              }
                              size="small"
                              style={{ minWidth: "175px" }}
                              onChange={(event) =>
                                handleRoleChange(
                                  teamMember.email,
                                  event.target.value
                                )
                              } // Update to pass the correct email and role
                              renderValue={(selected) => (
                                <span className="text-md">
                                  Role:{" "}
                                  <strong
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {selected}
                                  </strong>
                                </span>
                              )}
                            >
                              <MenuItem value="primary">Primary</MenuItem>
                              <MenuItem value="team member">
                                Team Member
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="order-1 sm:order-2 sm:ml-3">
                          {teamMember.login_type !== "primary" && (
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleDeleteTeamMember(teamMember.email)
                              }
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// 1. React Imports:
import { useState, useEffect } from "react";

// 2. Third-Party Library Imports:
import { Box, Button, Chip, Drawer, Grid, Stack } from "@mui/material";

// 3. Component Imports:
import { AdminCreateCustomer } from "../../components/customers/AdminCreateCustomer";
import { AdminUpdateCustomer } from "../../components/customers/AdminUpdateCustomer";
import DataTable from "../../components/DataTable";
import { SnackbarNotification } from "../../components/SnackbarNotification";

import { req } from "utils/api";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from "@mui/icons-material/Person"; // Added PersonIcon import

// --------------------------------------------------------------

export function AdminCustomers() {
  /*-------------------------------------
  State Variables and Hooks
	-------------------------------------*/
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null); // To hold the content to be displayed in the drawer
  const [refreshCounter, setRefreshCounter] = useState(0); // State to trigger refresh
  const [allLocations, setAllLocations] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/
  const columns = [
    {
      field: "account_id",
      headerName: "ID",
    },
    {
      field: "account_name",
      headerName: "Account",
      formatter: (value, row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
            {value}
            {row.additional_logins.length > 0 && (
            <Chip
              size="small"
              className="ml-4 pl-2 pr-1"
              variant="outlined"
              label={row.additional_logins.length}
              icon={<GroupsIcon />}
            />
          )}
        </div>
      ),
    },
    {
      field: "account_locations",
      headerName: "Locations/Sites",
      formatter: (value, row) => {
        const locationTypes = {};

        row.account_locations.forEach((location) => {
          if (!locationTypes[location.location_relationship]) {
            locationTypes[location.location_relationship] = 1;
          } else {
            locationTypes[location.location_relationship]++;
          }
        });

        return (
          <Stack direction="row" spacing={1}>
            {Object.entries(locationTypes).map(([type, count]) => (
              <Chip
                key={type}
                label={`${type}: ${count}`}
                size="small"
                color="primary"
                variant="outlined"
              />
            ))}
          </Stack>
        );
      },
    },

    // other columns if needed
  ];

const createAccountComponent = {};

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const closeDataDrawer = () => {
    setDrawerOpen(false);
  };

  const reloadCustomers = () => {
    // Trigger a refresh by incrementing the counter
    setRefreshCounter((prev) => prev + 1);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickAdd = () => {
    setDrawerContent(
      <AdminCreateCustomer
        allLocations={allLocations}
        reloadCustomers={reloadCustomers}
        closeDataDrawer={closeDataDrawer}
      />
    );
    setDrawerOpen(true);
  };

  const handleClickEdit = (customer) => {
    setDrawerContent(
      <AdminUpdateCustomer
        allLocations={allLocations}
        reloadCustomers={reloadCustomers}
        closeDataDrawer={closeDataDrawer}
        customer={customer}
        deleteAccount={deleteAccount}
        createTeamMember={createTeamMember}
        deleteTeamMember={deleteTeamMember}
      />
    );
    setDrawerOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  /*-------------------------------------
		Effects
	-------------------------------------*/
  useEffect(() => {
    document.title = "Manage Customers";
    loadLocations();
  }, []);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const loadLocations = async () => {
    const response = await req("get", "admin/get_locations?all=yes");
    if (response.success) {
      setAllLocations(response.data);
    }
  };

  const deleteAccount = (account_id) => {
    try {
      const result = req("POST", "admin/delete_account", {
        id: account_id,
      });
      setSnackbarMessage("Account deleted successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage(`Failed to delete account: ${error}`);
      setSnackbarSeverity("error");
    } finally {
      reloadCustomers();
      setDrawerOpen(false);
      setOpenSnackbar(true);
    }
  };

  const createTeamMember = async (data) => {
    try {
      const response = await req("POST", "admin/create_team_member", data);

      if(response.success === true){
        setSnackbarMessage("Team Member created successfully.");
        setSnackbarSeverity("success");
        reloadCustomers();
        return true;
      } else {
        // TODO: Log the error
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to create administrator:", {error});
      setSnackbarMessage(`Failed to create Team Member`);
      setSnackbarSeverity("error");
      return false;
    } finally {
      setOpenSnackbar(true);
    }
  };

  const deleteTeamMember = async (data) => {};

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { width: "50%" } }}
      >
        {drawerContent}
      </Drawer>
      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, boxShadow: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                Customers
              </h1>
              <div className="subtitle">Review Customer Accounts</div>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickAdd}
              >
                Add Customer
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <DataTable
              url="admin/get_accounts?type=customer"
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              refresh={refreshCounter} // Pass the refresh counter as a prop
              rowClickAction={handleClickEdit}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}

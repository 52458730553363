import "../assets/css/authStyle.css";
import logo from '../assets/images/logo-full.png';

import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export function AuthLayout() {

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('auth');
    }, []);

    return (
        <div className="content" style={{ margin: '0 auto'}}>
            <div className="page--card-wrapper">
                <div className=""><img src={logo} alt="Tomra logo" /></div>
                <Outlet />
            </div>
        </div>
    )
}
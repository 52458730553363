// 1. React Imports:
import React, { useState, useEffect, useCallback } from "react";

// 2. Third-Party Library Imports:
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import AddIcon from "@mui/icons-material/Add";
import LoaderIcon from "@mui/icons-material/Loop";
import { useToast } from "contexts/ToastContext"; // Import the useToast hook
// 3. Custom Hooks:

// 4. Component Imports:

// 5. Stylesheets:

// 6. Utility Functions or Constants:
import { upload, req } from "utils/api";


// 7. Assets:

export function FileManager() {
  const { showToast } = useToast(); // Get the showToast function from the context
  const [filesUploading, setFilesUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files

    //make sure they are csv files
    const files = acceptedFiles.filter((file) => file.type === "text/csv");
    if (files.length === 0) {
      showToast("Please upload a CSV file",'error');
      return;
    }
    setFilesUploading(true);
    try {
      for(let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("file", files[i]);
        await upload("admin/upload_data", files[i]);
      }
      showToast("All files uploaded successfully", 'success'); // Show success toast
    } catch (error) {
      showToast("Error uploading files", 'error'); // Show error toast
    } finally {
      console.log("Finally");
      setFilesUploading(false);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  /*--------------------------------------
		  State Variables and Hooks
	-------------------------------------*/

  /*--------------------------------------
		  Derived Data and Functions
	  -------------------------------------*/
  const getRecentUploads = async () => {
    //get the recent uploads
    let uploads = await req("get", "admin/get_recent_uploads");
    console.log("RecentUploads", uploads);
    setFilesUploaded(uploads);
  };
  /*--------------------------------------
	  Utility Functions
	 -------------------------------------*/

  /*--------------------------------------
		  Event Handlers
	-------------------------------------*/

  const handleClickAdd = () => {};

  /*--------------------------------------
		  Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "File Manager";
    getRecentUploads();
  }, []);

  /*--------------------------------------
		  Async Functions
    -------------------------------------*/

  const filesHtml = filesUploaded.map((file) => (
    <div key={file.id} className="flex items-center p-4 border-b">
      <div className="flex items-center justify-center w-10 h-10 mr-4 bg-gray-200 rounded-full">
        <FileIcon className="w-6 h-6" />
      </div>
      <div className="flex flex-col">
        <p className="text-lg font-medium">{file.file_name}</p>
        <p className="text-sm text-gray-500">
          Uploaded on {new Date(file.time_received).toLocaleString()}
        </p>
      </div>
    </div>
  ));

  return (
    <div id="files" className="flex flex-auto flex-col w-full relative">
      <div id="" className="flex flex-auto flex-col w-full relative">
        <div className="flex min-w-0 flex-auto flex-col dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="bg-card flex flex-col items-start border-b p-6 dark:bg-transparent sm:flex-row sm:items-center sm:justify-between sm:py-12 md:px-8">
            {filesUploading ? (
              <div className="flex items-center justify-center w-full h-full">
                <LoaderIcon className="w-6 h-6 animate-spin" />
              </div>
            ) : (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  disableElevation
                  onClick={() => handleClickAdd()}
                >
                  Upload File
                </Button>
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
            )}

          
          </div>
          <div className="flex flex-auto overflow-hidden border-t">
            <div className="flex flex-auto flex-col overflow-hidden sm:mb-18 sm:overflow-y-auto">
              {filesHtml}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

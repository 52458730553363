import { useEffect, useState } from "react";

import { useLocation, NavLink } from "react-router-dom";
import BarChartIcon from '@mui/icons-material/BarChart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RecyclingIcon from '@mui/icons-material/Recycling';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StoreIcon from '@mui/icons-material/Store';
import TableChartIcon from '@mui/icons-material/TableChart';

import logo from '../assets/images/logo-full-white.png';

export function NavbarAdmin() {

	const [activeSubmenu, setActiveSubmenu] = useState(null);
	const location = useLocation();

	const handleClickSubmenu = (index) => {
		setActiveSubmenu(prev => (prev === index ? null : index));
	};

	// Determine default active submenu based on URL path
    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        if (pathSegments.length > 2) {
            setActiveSubmenu(pathSegments[1]);
        } else {
			setActiveSubmenu(null);
		}
    }, [location.pathname]);

    return (
		<div className="vertical-navigation-wrapper">
			<div className="vertical-navigation-header"></div>
			<div className="vertical-navigation-content ps ps--active-y">
				<div className="vertical-navigation-content-header">
					<div className="flex items-center p-6">
						<img src={logo} className="w-30" />
					</div>
				</div>
				<div id="vertical-navigation-group-item" className="ng-star-inserted">
					{/* Nav Section Heading */}
					{/* <div className="vertical-navigation-item-wrapper vertical-navigation-item-has-subtitle">
						<div className="vertical-navigation-item">
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span>Dashboards</span>
								</div>
								<div className="vertical-navigation-item-subtitle ng-star-inserted">
									<span>Unique dashboard designs</span>
								</div>
							</div>
						</div>
					</div> */}
					<div id="vertical-navigation-basic-item">
						<div className="vertical-navigation-item-wrapper">
							<NavLink 
								className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
								to="/admin/dashboard"
							>
								<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color"><DashboardIcon /></div>
								<div className="vertical-navigation-item-title-wrapper">
									<div className="vertical-navigation-item-title">
										<span>Dashboard</span>
									</div>
								</div>
							</NavLink>
							<NavLink 
								className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
								to="/admin/customers"
							>
								<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color"><StoreIcon /></div>
								<div className="vertical-navigation-item-title-wrapper">
									<div className="vertical-navigation-item-title">
										<span>Customers</span>
									</div>
								</div>
							</NavLink>
							<NavLink 
								className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
								to="/admin/locations"
							>
								<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color"><RecyclingIcon /></div>
								<div className="vertical-navigation-item-title-wrapper">
									<div className="vertical-navigation-item-title">
										<span>Locations</span>
									</div>
								</div>
							</NavLink>
						</div>
					</div>
				</div>
				{/* Nav Divider */}
				<div id="vertical-navigation-divider-item">
					<div className="vertical-navigation-item-wrapper divider"></div>
				</div>
				<div id="vertical-navigation-group-item" className="ng-star-inserted">
					{/* Nav Section Heading */}
					<div className="vertical-navigation-item-wrapper vertical-navigation-item-has-subtitle">
						<div className="vertical-navigation-item">
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span>Analytics</span>
								</div>
								<div className="vertical-navigation-item-subtitle ng-star-inserted">
									<span>Detailed reports, maps and logs data.</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id="vertical-navigation-collapsable-item"
					className={
						activeSubmenu === "analytics"
						? 'vertical-navigation-item-expanded'
						: 'vertical-navigation-item-collapsed'
					}
					onClick={() => handleClickSubmenu("analytics")}
				>
					<div className="vertical-navigation-item-wrapper">
						<div className="mat-mdc-tooltip-trigger vertical-navigation-item">
							<BarChartIcon className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color" aria-hidden="true" />
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span className="ng-tns-c321347134-7">Analytics</span>
								</div>
							</div>
							<ChevronRightIcon className="mat-icon notranslate vertical-navigation-item-arrow icon-size-4 mat-icon-no-color" aria-hidden="true" />
						</div>
					</div>
					{activeSubmenu === "analytics" && (
						<div className="vertical-navigation-item-children">
							<div id="vertical-navigation-basic-item">
								<div className="vertical-navigation-item-wrapper">
									<NavLink 
										className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
										to="/admin/analytics/reports"
									>
										<div className="vertical-navigation-item-title-wrapper">
											<div className="vertical-navigation-item-title">
												<span>Reports</span>
											</div>
										</div>
									</NavLink>
								</div>
							</div>
							<div id="vertical-navigation-basic-item">
								<div className="vertical-navigation-item-wrapper">
									<NavLink 
										className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
										to="/admin/analytics/maps"
									>
										<div className="vertical-navigation-item-title-wrapper">
											<div className="vertical-navigation-item-title">
												<span>Interactive Map</span>
											</div>
										</div>
									</NavLink>
								</div>
							</div>
						</div>
					)}
				</div>
				<div id="vertical-navigation-basic-item">
					<div className="vertical-navigation-item-wrapper">
						<NavLink 
							className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
							to="/admin/log"
						>
							<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color"><TableChartIcon /></div>
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span>Logs</span>
								</div>
							</div>
						</NavLink>
					</div>
				</div>
				{/* Nav Divider */}
				<div id="vertical-navigation-divider-item">
					<div className="vertical-navigation-item-wrapper divider"></div>
				</div>
				<div id="vertical-navigation-basic-item">
					<div className="vertical-navigation-item-wrapper">
						<NavLink 
							className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
							to="/admin/file-manager"
						>
							<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color">
								<CloudQueueIcon />
							</div>
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span>File Manager</span>
								</div>
							</div>
						</NavLink>
					</div>
				</div>
				<div id="vertical-navigation-basic-item">
					<div className="vertical-navigation-item-wrapper">
						<NavLink 
							className={({ isActive }) => `vertical-navigation-item${isActive ? ' vertical-navigation-item-active' : ''}`} 
							to="/admin/administrators"
						>
							<div className="mat-icon notranslate vertical-navigation-item-icon mat-icon-no-color">
								<PeopleAltIcon />
							</div>
							<div className="vertical-navigation-item-title-wrapper">
								<div className="vertical-navigation-item-title">
									<span>Administrators</span>
								</div>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}
import { useEffect, useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export function LinkedAccountsTable({data, handleDeleteLinkedAccount}) {
	/*--------------------------------------
    State Variables
  -------------------------------------*/
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("account_name"); // default sorting by account_name

    /*--------------------------------------
    Utility Functions
  -------------------------------------*/
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedAccounts = [...data].sort((a, b) => {
    if (orderDirection === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
    }
  });

  useEffect(() => {
    // Any logic that needs to run when refreshCounter changes.
    // For example, refreshing the table data
    console.log("Refreshing table due to refreshCounter change.");
  }, [data]);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* Name column with sort label */}
              <TableCell>
                <TableSortLabel
                  active={orderBy === "account_name"}
                  direction={
                    orderBy === "account_name" ? orderDirection : "asc"
                  }
                  onClick={() => handleSortRequest("account_name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              {/* Relationship column with sort label */}
              <TableCell>
                <TableSortLabel
                  active={orderBy === "location_relationship"}
                  direction={
                    orderBy === "location_relationship" ? orderDirection : "asc"
                  }
                  onClick={() => handleSortRequest("location_relationship")}
                >
                  Relationship
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell> {/* For Delete Icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAccounts.map((account) => (
              <TableRow key={account.account_id}>
                <TableCell>{account.account_name}</TableCell>
                <TableCell>{account.location_relationship}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Delete Account"
                    size="small"
                    onClick={() => handleDeleteLinkedAccount(account)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// | | - TODO: Change AccountContext.Provider value to a dynamic value.
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { Button, CssBaseline, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { AccountCircleOutlined, Logout, Settings } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import "../assets/css/main_v1.css";

import logo from '../assets/images/logo-full.png';

import UserAuth from "../features/authentication/hooks/useAuth";

export function CustomerLayout() {

    const { user, logout } = UserAuth();
    // console.log('CustomerLayout', user);
    // const navigate = useNavigate();
	
    const handleSignOut = async () => {
        try {
            logout();
        } catch (error) {
            console.log(error);
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

	useEffect(() => {
		document.body.classList.remove('auth');
	}, []);

    return (<>
		<CssBaseline />
		<div id="layout" style={{ minHeight: '100vh' }}>
			<div>
				<div className="flex w-full min-w-0 flex-auto flex-col">
					<div className="bg-card fixed z-49 flex h-16 w-full flex-0 items-center px-4 shadow md:px-0 print:hidden">
						<div className="flex flex-auto flex-row max-w-6xl mx-auto">
							<div className="flex items-center">
								<Link to="/customer/home">
									<img src={logo} className="w-30" alt="Tomra" />
								</Link>
							</div>
							<div className="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
								<Button
									onClick={handleClick}
									startIcon={<AccountCircleOutlined />}
									endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
									className="mat-mdc-tooltip-trigger mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base ml-6 capitalize"
									sx={{ ml: 2 }}
									aria-controls={open ? 'user-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
								>
									{user && user.accountName ? user.accountName : 'Username'}
								</Button>
								<Menu
									anchorEl={anchorEl}
									id="user-menu"
									open={open}
									onClose={handleClose}
									onClick={handleClose}
									PaperProps={{
										elevation: 0,
										sx: {
											overflow: 'visible',
											filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
											minWidth: 220,
											mt: 1.5,
											'& .MuiAvatar-root': {
												width: 32,
												height: 32,
												ml: -0.5,
												mr: 1,
											},
											'&:before': {
												content: '""',
												display: 'block',
												position: 'absolute',
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: 'background.paper',
												transform: 'translateY(-50%) rotate(45deg)',
												zIndex: 0,
											},
										},
									}}
									transformOrigin={{ horizontal: 'right', vertical: 'top' }}
									anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
								>
									<MenuItem component={Link} to="/customer/settings">
										<ListItemIcon>
											<Settings fontSize="small" />
										</ListItemIcon>
										Settings
									</MenuItem>
									<Divider />
									<MenuItem onClick={handleSignOut}>
										<ListItemIcon>
											<Logout fontSize="small" />
										</ListItemIcon>
										Logout
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>
					<div className="relative flex flex-auto flex-col mt-16">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
    </>)
}
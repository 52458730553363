import React, { useEffect, useState, useRef } from "react";
//import button from MUI
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import {
  FaRecycle,
  FaWater,
  FaLeaf,
  FaCar,
  FaBolt,
  FaMoneyBillWave,
  FaShower,
  FaTrash,
  FaPrint
} from "react-icons/fa";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function EnvironmentalStats({ totalUnits }) {
  console.log("EnvironmentalStats totalUnits", totalUnits);
  const [enviroStats, setEnviroStats] = useState({
    water: 0,
    shower: 0,
    landfill: 0,
    climate: 0,
    driving: 0,
    energy: 0,
    tv: 0,
    refund: 0,
    totalUnits: 0,
  });
  const enviroCalculator = (totalUnits) => {
    setEnviroStats({
      water: totalUnits * 5.874162,
      shower: totalUnits * 5.874162 * 0.11107961727,
      landfill: totalUnits * 0.06,
      climate: totalUnits * 0.120368,
      driving: totalUnits * 0.120368 * 3.948406929,
      energy: totalUnits * 1.30388,
      tv: totalUnits * 1.30388 * 1.049176305,
      refund: totalUnits * 0.1,
      totalUnits: totalUnits,
    });
  };
  const formatCurrency = (totalUnits) => {
    return totalUnits?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const formatNumber = (totalUnits, nbrDecimals = 2) => {
    return totalUnits?.toFixed(nbrDecimals).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  useEffect(() => {
    enviroCalculator(totalUnits);
  }, [totalUnits]);

  const [logo, setLogo] = useState(null);
  const contentRef = useRef(null);
  const printRef = useRef(null);

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setLogo(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const generatePDF = () => {
    const content = printRef.current;
    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('environmental_impact.pdf');
    });
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Button 
        onClick={generatePDF}
        variant={"text"}
      >
        <FaPrint style={{marginRight:"1rem"}} /> Print PDF
      </Button>

      <div ref={printRef} style={{ 
        backgroundColor: '#f0f0f0', 
        width: '100%', 
        minHeight: 'calc(100vh - 60px)', // Adjust for the button height
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <h1 style={{ fontSize: '36px', color: '#333', marginBottom: '10px' }}>Number of containers recycled</h1>
          <div style={{ fontSize: '72px', fontWeight: 'bold', color: '#1e40af' }}>{formatNumber(enviroStats.totalUnits, 0)}</div>
        </div>
        
        <StatRow
          icon={<FaWater size={48} color="#22c55e" />}
          title="Water savings"
          mainStat={`${formatNumber(enviroStats.water, 2)} litres`}
          equivalentStat={`${formatNumber(enviroStats.shower, 0)} minutes of showering`}
        />

        <StatRow
          icon={<FaBolt size={48} color="#eab308" />}
          title="Energy saved"
          mainStat={`${formatNumber(enviroStats.energy, 2)} Megajoules`}
          equivalentStat={`${formatNumber(enviroStats.tv, 0)} hours of TV time`}
        />

        <StatRow
          icon={<FaLeaf size={48} color="#16a34a" />}
          title="CO2 emissions avoided"
          mainStat={`${formatNumber(enviroStats.climate, 2)} Kgs`}
          equivalentStat={`${formatNumber(enviroStats.driving, 0)} Kms of driving`}
        />
      </div>
    </div>
  );
}

function StatRow({ icon, title, mainStat, equivalentStat }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '40px', textAlign: 'center' }}>
      <div style={{ marginBottom: '10px' }}>{icon}</div>
      <h2 style={{ fontSize: '32px', color: '#333', marginBottom: '10px' }}>{title}</h2>
      <p style={{ fontSize: '24px', color: '#333', marginBottom: '5px' }}>
        You've saved <span style={{ color: '#eab308', fontWeight: 'bold' }}>{mainStat}</span>
      </p>
      <p style={{ fontSize: '24px', color: '#333' }}>
        That's the equivalent of <span style={{ color: '#eab308', fontWeight: 'bold' }}>{equivalentStat}</span>
      </p>
    </div>
  );
}

// 1. React Imports:
import { useState } from "react";

// 2. Third-Party Library Imports:
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { SnackbarNotification } from "../../components/SnackbarNotification";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import CloseIcon from "@mui/icons-material/Close";
import Email from "@mui/icons-material/Email";
import { req } from "utils/api";

export function AdminCreateCustomer({ closeDataDrawer, reloadCustomers }) {
  /*--------------------------------------
    State Variables
  -------------------------------------*/
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerDescription, setCustomerDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  const validateForm = () => {
    let formErrors = {};

    if (!customerName) {
      formErrors.name = "Customer name is required.";
    }
    if (!customerEmail) {
      formErrors.email = "Customer email is required.";
    } else if (!/\S+@\S+\.\S+/.test(customerEmail)) {
      formErrors.email = "Customer email is invalid.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const createCustomer = async () => {
    if (!validateForm()) return;

    const data = {
      name: customerName,
      email: customerEmail,
      //address: customerAddress,
      description: customerDescription,
    };

    try {
      setFormProcessing(true);
      const response = await req("post", "admin/create_customer", data);
      setFormProcessing(false);

      setSnackbarMessage("Account created successfully.");
      setSnackbarSeverity("success");
      reloadCustomers();
      closeDataDrawer();
    } catch (error) {
      setSnackbarMessage(`Failed to create account: ${error}`);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">Add new customer</div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 pt-0 sm:p-12 sm:pt-0">
        <div className="w-full max-w-3xl border-t">
          {loading ? (
            <div className="flex justify-center items-center py-16">
              <CircularProgress />
            </div>
          ) : (
            <form noValidate>
              <div className="mt-8">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_name">Customer Name</label>
                  <TextField
                    id="customer_name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_email">Customer Email</label>
                  <TextField
                    id="customer_email"
                    type="email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_address">Customer Address</label>
                  <TextField
                    id="customer_address"
                    value={customerAddress}
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_description">Notes</label>
                  <TextField
                    id="customer_description"
                    value={customerDescription}
                    onChange={(e) => setCustomerDescription(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ display: "flex", alignSelf: "flex-start" }}
                        >
                          <SubjectIcon style={{ alignSelf: "flex-start" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                  />
                </div>
              </div>
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                <Button
                  onClick={closeDataDrawer}
                  variant="outlined"
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-auto"
                  variant="contained"
                  onClick={createCustomer}
                  disableElevation
                  disabled={formProcessing}
                >
                  {formProcessing ? "Creating..." : "Create Customer"}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}

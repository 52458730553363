// 1. React Imports:
import { useEffect, useRef, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Box,
  Autocomplete,
} from "@mui/material";

// 3. Component Imports:

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Email } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubjectIcon from "@mui/icons-material/Subject";
import LocationOnIcon from "@mui/icons-material/LocationOn";

// --------------------------------------------------------------

import { alpha } from "@mui/material/styles";

function TableTeamMembers({ additionalLogins, deleteTeamMember }) {
  const [teamMembers, setTeamMembers] = useState([]);

  const handleDeleteTeamMember = async (user_id) => {
    const data = {
      id: user_id,
    }

  };

  useEffect(() => {
    if (additionalLogins) {
      setTeamMembers(additionalLogins);
    }
  }, [additionalLogins]);

  return (
    <>
      <div className="mt-4">
        <div className="flex flex-auto flex-col border-t">
          {teamMembers.map((item) => (
            <div
              className="flex flex-col p-2 border-b sm:flex-row sm:items-center xl:py-4"
              key={item.user_id}
            >
              <div className="flex items-center">
                <div>
                  <div className="font-medium">{item.login_name}</div>
                  <div className="text-secondary">{item.email}</div>
                </div>
              </div>
              <div className="mt-4 flex items-center sm:ml-auto sm:mt-0">
                {item.login_type !== "primary" && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteTeamMember(item.user_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

// --------------------------------------------------------------

export function AdminUpdateCustomer({
  customer,
  closeDataDrawer,
  reloadCustomers,
  allLocations,
  deleteAccount,
  createTeamMember,
  deleteTeamMember,
}) {
  console.log("customer:", customer);
  const loginNameRef = useRef(null);
  const emailRef = useRef(null);
  const [accountId, setAccountId] = useState(customer?.account_id || "");
  const [name, setName] = useState(customer?.account_name || "");
  const [email, setEmail] = useState(customer?.email || "");
  const [status, setStatus] = useState(customer?.status || "active");
  const [address, setAddress] = useState(customer?.address || "");
  const [description, setDescription] = useState(customer?.description || "");
  const [additionalLogins, setAdditionalLogins] = useState(
    customer?.additional_logins || []
  );
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [locations, setLocations] = useState(customer?.account_locations || []);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [searchLocations, setSearchLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openRelationshipDialog, setOpenRelationshipDialog] = useState(false);
  const [showAddTeamMember, setShowAddTeamMember] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [formProcessing, setFormProcessing] = useState(false);

  const handleDeleteLocation = async (locationToDelete) => {
    try {
      //if good response then remove it from the customer.account_locations
      setLocations(
        locations.filter(
          (location) =>
            location.account_location_id !==
            locationToDelete.account_location_id
        )
      );
      let response = await req("post", "admin/remove_account_from_location", {
        account_id: customer.account_id,
        location_id: locationToDelete.location_id,
      });
      console.log("Response from remove_account_from_location:", response);
      reloadCustomers();
    } catch (error) {
      console.error("Error deleting location:", error);
      alert("Failed to delete location.");
    }
  };

  const handleAddLocation = async (newLocation) => {
    if (!newLocation) return;
    try {
      const response = await req("post", "admin/add_customer_location", {
        customer_id: customer.account_id,
        location_id: newLocation.id,
      });
      reloadCustomers();
    } catch (error) {
      console.error("Error adding location:", error);
      alert("Failed to add location.");
    }
  };

  const handleSaveAccount = async () => {
    if (!name || !email) {
      alert("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      const data = {
        account_id: customer?.account_id,
        user_id: customer?.user_id,
        email,
        name,
        status,
        address,
        description,
      };

      await req("post", "admin/update_customer", data);
      reloadCustomers();
      closeDataDrawer();
    } catch (error) {
      console.error("Error updating customer:", error);
      alert("Failed to update customer.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleAddTeamMember = () => {
    setShowAddTeamMember(prevState => !prevState)
  };
  

  const handleClickDeleteAccount = () => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      deleteAccount(accountId);
    }
  };

  const handleLinkUser = () => {
    if (accountId === "") {
      console.warn("No Customer ID provided.", accountId);
      return;
    }

    if (selectedUserId === "") {
      console.warn("No user selected.");
      return;
    }

    try {
    } catch (error) {}

    document.getElementById("linkUser").classList.add("hidden");
    setSelectedUserId(""); // Rest the selected account
  };

  const handleLocationSearch = async (searchTerm) => {
    if (searchTerm) {
      //console.log("Searching for:", searchTerm, allLocations);
      setSearchLocations(
        allLocations.filter(
          (location) =>
            (location.location_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              location.location_g_id
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            !locations.some(
              (existingLocation) =>
                existingLocation.location_id === location.location_id
            )
        )
      );
    } else {
      setSearchLocations([]);
    }
  };

  const handleLocationSelect = (event, newValue) => {
    setSelectedLocation(newValue);
    if (newValue) {
      setOpenRelationshipDialog(true);
    }
  };

  const handleRelationshipSelect = async () => {
    if (selectedLocation && selectedRelationship) {
      try {
        console.log("Selected Location:", selectedRelationship, locations);
        setOpenRelationshipDialog(false);
        setSelectedLocation(null);
        setSelectedRelationship("");
        setLocations([
          ...locations,
          { ...selectedLocation, location_relationship: selectedRelationship },
        ]);

        let add_reponse = await req("post", "admin/add_account_to_location", {
          account_id: customer.account_id,
          location_id: selectedLocation.location_id,
          relationship: selectedRelationship,
        });
        console.log("Response from add_reponse:", add_reponse);

        reloadCustomers();
      } catch (error) {
        console.error("Error associating location:", error);
        alert("Failed to associate location.");
      }
    }
  };

  const getChipColor = (relationship) => {
    switch (relationship) {
      case "Site Contact":
        return "#FF8A80"; // Lighter red
      case "Property Manager":
        return "#B9F6CA"; // Lighter green
      case "LGA Contact":
        return "#82B1FF"; // Lighter blue
      case "Redemption Partner":
        return "#FFD180"; // Lighter orange
      default:
        return "#E0E0E0"; // Light grey (default)
    }
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickAddTeamMember = async (e) => {
    e.preventDefault();

    const data = {
      account_id: customer.account_id,
      login_name: loginNameRef.current.value,
      email: emailRef.current.value,
      login_type: "team member",
    };
    // console.log("Adding Team Member:", data);

    const response = await createTeamMember(data);
    if (response) {
      // Reload the Team Members
      handleToggleAddTeamMember(); // Hide the Add New Team Member form

      // Reset the form fields if needed
      loginNameRef.current.value = "";
      emailRef.current.value = "";
    }
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">Edit Customer</div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 py-0 sm:p-12 sm:py-0">
        <div className="w-full max-w-3xl border-t">
          {loading ? (
            <CircularProgress />
          ) : (
            <form noValidate>
              <div className="mt-8">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_name">Customer Name</label>
                  <TextField
                    id="customer_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_name">Customer Email</label>
                  <TextField
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="mt-4">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="customer-locations-content"
                    id="customer-locations-header"
                  >
                    <Typography variant="h6">
                      Customer Locations ({locations.length})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                    >
                      <Box>
                        <Typography variant="subtitle1" gutterBottom>
                          Add Location
                        </Typography>
                        <Autocomplete
                          options={searchLocations}
                          getOptionLabel={(option) => option.location_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search locations"
                              variant="outlined"
                            />
                          )}
                          onInputChange={(event, newInputValue) =>
                            handleLocationSearch(newInputValue)
                          }
                          onChange={handleLocationSelect}
                          fullWidth
                        />
                      </Box>
                      <Divider />
                      <Box>
                        <Typography variant="subtitle1" gutterBottom>
                          Existing Locations
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          {locations.map((location) => {
                            const chipColor = getChipColor(
                              location.location_relationship
                            );
                            return (
                              <Chip
                                key={location.location_id}
                                icon={<LocationOnIcon />}
                                variant="outlined"
                                label={`${location.location_name} (${location.location_relationship})`}
                                onDelete={() => handleDeleteLocation(location)}
                                sx={{
                                  backgroundColor: chipColor,
                                  color: "#000000",
                                  "&:hover": {
                                    backgroundColor: alpha(chipColor, 0.8),
                                  },
                                  "& .MuiChip-deleteIcon": {
                                    color: "#000000",
                                    "&:hover": { color: "#FF0000" },
                                  },
                                  fontWeight: 500,
                                  padding: "4px 0",
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_description">Notes</label>
                  <TextField
                    id="customer_description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ display: "flex", alignSelf: "flex-start" }}
                        >
                          <SubjectIcon style={{ alignSelf: "flex-start" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={2}
                  />
                </div>
              </div>
              <div className="mx-auto flex w-full max-w-3xl items-center justify-between pt-10">
                <div className="text-2xl font-bold">Team Management</div>
                <div className="flex items-center ml-auto">
                  <Button
                    onClick={handleToggleAddTeamMember}
                    startIcon={showAddTeamMember ? null : <AddIcon fontSize="small" />}
                  >
                    {showAddTeamMember ? "Cancel" : "Add Team Member"}
                  </Button>
                </div>
              </div>
              {showAddTeamMember && (
              <div
                id="linkUser"
                className="mx-auto flex w-full flex-col max-w-3xl items-end justify-between mt-4 pb-6"
              >
                <div className="w-full">
                  <div className="text-xl">Add a new Team Member</div>
                </div>
                <div className="mt-2 flex flex-row divide-y w-full">
                  <TextField
                    label="Name"
                    inputRef={loginNameRef}
                    required
                    fullWidth
                    size="small"
                  />
                  <TextField
                    label="Email"
                    type="email"
                    inputRef={emailRef}
                    required
                    fullWidth
                    size="small"
                    className="ml-4"
                  />
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="ml-4"
                    disableElevation
                    onClick={handleClickAddTeamMember}
                    disabled={formProcessing}
                  >
                    Add
                  </Button>
                </div>
              </div>
              )}
              {additionalLogins.length > 0 ? (
                <TableTeamMembers additionalLogins={additionalLogins} deleteTeamMember={deleteTeamMember} />
              ) : null}
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                <Button
                  onClick={handleClickDeleteAccount}
                  disabled={formProcessing}
                >
                  Delete
                </Button>
                <Button
                  className="ml-auto"
                  onClick={closeDataDrawer}
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleSaveAccount}
                  className="ml-4"
                  disabled={formProcessing}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Dialog
        open={openRelationshipDialog}
        onClose={() => setOpenRelationshipDialog(false)}
      >
        <DialogTitle>Select Relationship Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedRelationship}
            onChange={(e) => setSelectedRelationship(e.target.value)}
          >
            {[
              "Site Contact",
              "Property Manager",
              "LGA Contact",
              "Redemption Partner",
            ].map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRelationshipDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleRelationshipSelect} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from "react";
import DataTable from "../../components/DataTable";
import { Box, Typography } from "@mui/material";

export function Log() {
  const accountFormatter = (value, row) => {
    if (!value) {
      return "N/A";
    }
    return (
      <>
        {value}
        {row?.user_email && <div>{row.user_email}</div>}
      </>
    );
  };
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "account_name", headerName: "Account", formatter: accountFormatter },
    { field: "log_level", headerName: "Type" },
    { field: "message", headerName: "Topic" },
    { field: "created_at", headerName: "Date", formatter: (value) => new Date(value).toLocaleString() },
  ];

  return (
    <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
      <Box
        className="flex flex-auto flex-col w-full relative"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 2,
          p: 3,
          boxShadow: 1,
        }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          System Logs
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Review logs
        </Typography>
        <Box sx={{ mt: 2 }}>
          <DataTable
            url="admin/get_logs"
            columns={columns}
            paginationType="server" // Or 'client'
            initialPageSize={10}
            searchEnabled={true}
            additionalParams={
              {
                /* someParam: 'value' */
              }
            } // Pass any additional params needed for your API
          />
        </Box>
      </Box>
    </Box>
  );
}

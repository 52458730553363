
export function AdminAnalyticsMaps() {
    return (<>
		<div className="flex flex-col flex-auto">
			<div id="coming-soon" className="flex flex-auto flex-col">
				<div className="flex min-w-0 flex-auto flex-col items-center sm:justify-center md:p-8">
					<div className="flex overflow-hidden sm:bg-card sm:rounded-2xl sm:shadow md:w-full md:max-w-6xl ng-tns-c430065300-93">
						<div className="relative hidden h-full flex-auto items-center justify-center overflow-hidden bg-gray-800 p-16 dark:border-l md:flex lg:px-28 ng-tns-c430065300-93">
							<svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 pointer-events-none ng-tns-c430065300-93">
								<g fill="none" stroke="currentColor" strokeWidth="100" className="text-gray-700 opacity-25 ng-tns-c430065300-93">
									<circle r="234" cx="196" cy="23" className="ng-tns-c430065300-93"></circle>
									<circle r="234" cx="790" cy="491" className="ng-tns-c430065300-93"></circle>
								</g>
							</svg>
							<svg viewBox="0 0 220 192" width="220" height="192" fill="none" className="absolute -top-16 -right-16 text-gray-700 ng-tns-c430065300-93">
								<defs className="ng-tns-c430065300-93">
									<pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" className="ng-tns-c430065300-93">
										<rect x="0" y="0" width="4" height="4" fill="currentColor" className="ng-tns-c430065300-93"></rect>
									</pattern>
								</defs>
								<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" className="ng-tns-c430065300-93"></rect>
							</svg>
							<div
								className="relative z-10 w-full max-w-2xl"
								style={{ minHeight: "10rem" }}
							>
								<div className="text-7xl font-bold leading-none text-gray-100">
									<div>Pending: Under Construction</div>
								</div>
								<div className="mt-6 text-lg leading-6 tracking-tight text-gray-400">We're working hard to bring this webpage to you soon.</div>
								<div className="text-lg leading-6 tracking-tight text-gray-400">If you think this is an error please let us know.</div>
								<div className="mt-6 text-lg leading-6 tracking-tight text-gray-400">Analytics: Interactive Maps</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>);
}
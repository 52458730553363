import { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, Button, CircularProgress, Box, Typography, Alert } from "@mui/material";
import { req } from "utils/api";

export function Forgot() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const forgotPassword = async (email) => {
    try {
      const forgotPasswordUrl = "forgot_password";
      const result = await req("POST", forgotPasswordUrl, { email });
  
      if (!result.success) {
        throw new Error(result.message || "Failed to send password reset email.");
      }
  
      return result.message;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "An error occurred during password reset request."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setMessage("");
    try {
      const resultMessage = await forgotPassword(email);
      setMessage(resultMessage);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Reset Password
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Enter your email address below and we will send you a link to reset your password.
        </Typography>
        {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
        {!message && (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="email"
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              Reset password
            </Button>
          </form>
        )}
        <Typography variant="body2" sx={{ mt: 2 }}>
          Return to <Link to="/login">Login</Link>
        </Typography>
      </Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

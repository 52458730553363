import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  CircularProgress,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export function CustomerSecurity({
  data,
  handleDrawerToggle,
  handleUpdateUser,
}) {
  const [formData, setFormData] = useState({
    login_name: "",
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formChanged, setFormChanged] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  /*--------------------------------------
    Event Handlers
    -------------------------------------*/

  // Toggle password visibility
  const handleClickShowPassword = (field) => () => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Handle changes to form inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormChanged(true);
  };

  // Save changes
  const handleSave = (event) => {
    event.preventDefault();
  
    const { login_name, email, password, newPassword, confirmNewPassword } = formData;
  
    // Check if new password and confirm password fields match
    if (newPassword && newPassword !== confirmNewPassword) {
      setError("New password and confirmation do not match.");
      return;
    }
  
    // Prepare the update data, excluding password fields if they are empty
    const updateData = {
      login_name,
      email,
    };
  
    // Only add password fields if they are non-empty (indicating a password change)
    if (password) {
      updateData.password = password;
    }
    if (newPassword) {
      updateData.newPassword = newPassword;
    }
  
    // Clear the error and proceed with the update
    setError("");
    handleUpdateUser(updateData);
  };
  

  /*--------------------------------------
    Use effect for initial data load
    -------------------------------------*/
  useEffect(() => {
    const currentUser = data.secondary_logins.find(
      (login) => login.user_id === data.user_id
    );
    setFormData({
      login_name: currentUser.login_name,
      email: currentUser.email,
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    setLoading(false);
  }, [data]);

  return (
    <div className="flex-auto px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12">
      <div className="flex items-center">
        <IconButton
          className="-ml-2 lg:hidden"
          onClick={handleDrawerToggle}
          aria-label="Toggle drawer"
        >
          <MenuIcon />
        </IconButton>
        <div className="ml-2 text-3xl font-bold leading-none tracking-tight lg:ml-0">
          Security
        </div>
      </div>
      <div className="mt-8">
        <div id="settings-security">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <div className="w-full max-w-3xl">
              <form noValidate="" onSubmit={handleSave}>
                <div className="w-full">
                  <div className="text-xl">Personal Profile</div>
                  <div className="text-secondary">
                    The following information is personal to you.
                  </div>
                </div>
                <div className="mt-8 grid w-full gap-6 sm:grid-cols-4">
                  <div className="sm:col-span-4">
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      name="login_name"
                      value={formData.login_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="my-10 border-t"></div>
                <div className="w-full">
                  <div className="text-xl">Change your password</div>
                </div>
                <div className="mt-8 grid w-full gap-6 sm:grid-cols-4">
                  <div className="sm:col-span-4">
                      <InputLabel htmlFor="password">Current Password</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="password"
                        type={showPassword.current ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword("current")}
                            >
                              {showPassword.current ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="sm:col-span-4">
                      <InputLabel htmlFor="newPassword">New Password</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="newPassword"
                        type={showPassword.new ? "text" : "password"}
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword("new")}
                            >
                              {showPassword.new ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="sm:col-span-4">
                      <InputLabel htmlFor="confirmNewPassword">
                        Confirm New Password
                      </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="confirmNewPassword"
                        type={showPassword.confirm ? "text" : "password"}
                        name="confirmNewPassword"
                        value={formData.confirmNewPassword}
                        onChange={handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword("confirm")}
                            >
                              {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </div>
                {error && <div className="text-red-500 mt-2">{error}</div>}
                <div className="my-10 border-t"></div>
                <div className="flex items-center justify-end">
                  <Button variant="outlined">Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="ml-4"
                    disableElevation
                    disabled={!formChanged}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { TextField, Button, IconButton, Box, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockIconOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { req } from "../../utils/api";

export function AdminSettings() {
  const minScreenWidth = 1280;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [drawerOpen, setDrawerOpen] = useState(
    window.innerWidth > minScreenWidth
  );
  const [drawerContent, setDrawerContent] = useState("account");
  const [account, setAccount] = useState({
    account_name: "",
    email: "",
  });

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "admin/update_my_account";
    const result = await req("POST", url, {
      name: account.name,
      email: account.email,
    });

    if (result.success) {
      alert("Account updated successfully.");
    } else {
      alert(`Failed to update account: ${result.message}`);
    }
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (passwords.newPassword !== passwords.confirmPassword) {
      alert("New passwords do not match.");
      return;
    }

    if (passwords.newPassword && passwords.newPassword.length < 8) {
      alert("Password must be at least 8 characters long.");
      return;
    }

    const url = "admin/update_my_password";
    const result = await req("POST", url, {
      oldPassword: passwords.oldPassword,
      newPassword: passwords.newPassword,
    });

    if (result.success) {
      alert("Password updated successfully.");
      setPasswords({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      alert(`Failed to update password: ${result.message}`);
    }
  };

  async function get_account() {
    const url = "admin/get_account";
    const result = await req("GET", url);
    if (!result?.data) {
      console.error("Failed to fetch account data");
      // Handle error appropriately
    }
    setAccount(result.data);
  }

  useEffect(() => {
    document.title = "Settings - Account Management";
    get_account();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={3}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: "500px", marginBottom: "2rem" }}
      >
        <Box mb={2}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={account.account_name}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={account.email}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
			fullWidth
          className="w-100 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
          disableElevation
          type="submit"
        >
          Update Account
        </Button>
      </form>
      <form
        onSubmit={handlePasswordSubmit}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        <Typography variant="h4" gutterBottom>
          Update password
        </Typography>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Current Password"
            name="oldPassword"
            type="password"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            type="password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            variant="outlined"
            helperText="Must be at least 8 characters"
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Confirm New Password"
            name="confirmPassword"
            type="password"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            variant="outlined"
          />
        </Box>
        <Button fullWidth variant="contained" color="primary"  className="w-100 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
          disableElevation type="submit">
          Update Password
        </Button>
      </form>
    </Box>
  );
}

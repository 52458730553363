import LoginsStats from "components/analytics/loginsStats"

export function AdminDashboard() {
    return (
        <>
        <main className="page--content">
        
            <div className="p-2">
                <LoginsStats />
            </div>
        </main>
        </>
    );
}